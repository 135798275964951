import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"

const About = ({ data, location }) => {
  const about = data.allContentfulWeb.nodes[0].about
  return (
    <Layout location={location}>
      <div className="container">
        <div className="page-header page-header-big text-center"
             style={{ backgroundImage: `url(https://${about.file.url})`, backgroundPositionY: "65%" }}>
          <h1 className="text-white">Giới thiệu<span className="text-white">Nhà may Ngô Hường</span></h1>
        </div>
      </div>
      <div className="bg-light-2 pt-6 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-3 mb-lg-0">
              <h2 className="title">Tổng quan</h2>
              <p className="lead text-primary mb-3">Với hơn 30 năm kinh nghiệm trong lĩnh vực may mặc và thiết kế, xưởng
                may
                với hàng chục công nhân lành nghề.</p>
              <p className="mb-2"> Nhà may Ngô Hường tại thành phố Hồ Chí Minh cung cấp
                cho quý khách dịch vụ may mặc trọn gói từ khâu thiết kế, lên mẫu, ra rập đến sản xuất ra
                thành phẩm. Nhà may chúng tôi chuyên sản xuất đồng phục học sinh, đồ công sở, áo khoác,
                đơn hàng xưởng số lượng lớn và đa dạng các mặt hàng khác tùy theo nhu cầu của khách
                hàng.</p>
              <Link to={"/products"} className="btn btn-sm btn-minwidth btn-outline-primary-2">
                <span>Sản phảm của chúng tôi</span>
                <i className="icon-long-arrow-right" />
              </Link>
            </div>

            <div className="col-lg-6 offset-lg-1">
              <div className="about-images">
                <img src="../images/about/about-2.jpg" alt="Image"
                     className="about-img-front w-75" />
                <img src="../images/about/about-1.jpg" alt="Image"
                     className="w-50 about-img-back" />
              </div>

            </div>

          </div>

        </div>

      </div>
    </Layout>
  )
}
export default About

export const pageQuery = graphql`
  query {
    allContentfulWeb {
      nodes {
        about {
          title
          description
          file {
            url
          }
        } 
      }
    }
  }
`